import * as React from "react"
import Layout from "../../components/Layout";
import Location from "../../assets/images/Location-Image.png"
import * as styles from "./styles.module.scss"

const AboutPage = () => {
  return (
  <main>
    <title>Telemundo Center Studio Services | Our Company</title>
    <Layout false>
      <div className={styles.area}>
        <div className={styles.about}>
          ABOUT TELEMUNDO CENTER
        </div>
        <p>Telemundo Center is the only multimedia production and broadcasting facility in the nation that can produce news, sports, entertainment, and digital content under one roof. Opened in 2018, the $250 million global headquarters of NBCUniversal Telemundo Enterprises is a 570,000-square-foot facility with 13 highly adaptive studios that provide an unmatched capacity to produce content for the diverse appetites of 60 million U.S. Hispanics, the fastest-growing demographic in America.</p>
        <p>The modern and agile workspaces at Telemundo Center are designed to spark innovation and foster the creative energy and collaboration essential to a world-class media operation that produces more than 3,000 hours of original content annually.</p>
        <p className={styles.last}>In addition to props and wardrobe, we provide the following professional services, with competitive pricing and the ability to meet tight production timelines:</p>
        
        <ul className={styles.ul}>
          <li>Wood and Paint Shops for custom CNC fabrication and construction of sets, props, and additional specialty services</li>
          <li>Our Metal Shop can make your ideas a reality in steel, stainless steel, aluminum, and more</li>
          <li>Drapery manufacturing and installations, along with all your rental needs</li>
          <li>Furniture upholstery and manufacturing</li>
        </ul>

        <div className={styles.location}>
          <p>Telemundo Center - One Telemundo Way, Miami, Florida 33182</p>
          <p>NO WALK UP'S ALLOWED</p>
        </div>
        <img src={Location}></img>
      </div>
    </Layout>
  </main>
  );
};

export default AboutPage;